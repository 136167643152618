@tailwind base;
@tailwind components;
@tailwind utilities;

.header-clip {
  clip-path: inset(0px 0px -100px 0px);
}

html, body, #root {
  @apply h-full;
}

.rotating-background {
  background: 
    linear-gradient(180deg, 
    #FF5C00, #FE8640, #FEB88E, 
    #FFCCF4, #FFA8EC, #FF8BE6, 
    #9747FF, #B272FF, #D2AEFF, 
    #92DADB, #43C0C2, #00AAAD);
  background-size: 100% 1800%;
  animation: gradient 40s linear infinite;
  background-image: linear-gradient(45deg, 
    #FFFFFF00, FFFFFF99);
}

@keyframes gradient {
  0% {
      background-position: 100% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 100% 0%;
  }
}

.tpi-blur {
  backdrop-filter: blur(20px);
}

.tpi-marquee {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  /* position: fixed; */
  top: 0;
  left: 0;
}
